import React from "react";
import markFletcher from "../assets/mark-fletcher.jpg";
import HorizontalScrollCarousel from "../components/HorizontalScrollCarousel";
// import Newsletter from '../components/Newsletter'

const Home = () => {
  // const [opacity, setOpacity] = useState(1);
  // const [timedPopup, setTimePopout] = useState(false);

  // const handleScroll = () => {
  //   const scrollPosition = window.scrollY;
  //   const maxScroll = 1000;

  //   const newOpacity = 1 - (scrollPosition / maxScroll) * 0.5;

  //   setOpacity(Math.max(newOpacity, 0.5));
  // };

  // Newsletter Popup Code
  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimePopout(true);
  //   }, 15000)
  // }, [])

  return (
    <div className="lg:mt-48 2xl:mt-64 mt-32 md:mt-42">
      <div className="lg:whitespace-nowrap mx-auto text-[8vw] md:text-[8vw] leading-tight lg:leading-none lg:text-6xl 2xl:text-[4vw] font-extrabold flex flex-col px-4 pb-36 md:px-4 lg:px-16 2xl:px-32 2xl:tracking-wide">
        <span className="text-left lg:pl-0 animate-fade-right animate-delay-[350ms]">
          Established in the year of 2020, <br></br>
        </span>
        <span className="text-left animate-fade-left animate-delay-[700ms] lg:py-2.5 lg:pl-56 2xl:text-left 2xl:pl-[4em]">
          when the world yearned for
        </span>
        <span className="text-left lg:pl-10 lg:py-2.5 2xl:pl-12 animate-fade-right animate-delay-[900ms]">
          <span className="trebuchet-font font-bold special italic text-[#D0AD57]">
            beauty and brilliance
          </span>
          <span> behind a screen,</span>
        </span>
        <span className="text-left lg:py-2.5 lg:pl-20 2xl:pl-24 animate-fade-left animate-delay-[1100ms]">
          Lustre & Laud emerged as a
        </span>
        <span className="text-left lg:text-right 2xl:text-right lg:pr-28 2xl:pr-12 animate-fade-right animate-delay-[1300ms]">
          beacon of artistic ingenuity.
        </span>
      </div>

      <div className="lg:flex lg:flex-col lg:items-center pt-24 lg:pt-48 2xl:pt-80 snap-mandatory snap-y overflow-hidden lg:mb-24">
        <div className="h-screen sticky top-0 lg:h-screen lg:px-0">
          <img
            src={markFletcher}
            alt="place-holder"
            className="brightness-50 w-full h-full object-cover snap-center opacity-100 md:h-3/5 lg:h-4/5"
          />

          <div className="absolute bottom-0 lg:top-0 inset-x-0 lg:absolute lg:pt-20 2xl:pt-36 text-white lg:text-white items-center pb-72 md:mb-80">
            <p className="text-lg md:text-xl">
              <span>CALIFORNIA-BASED AGENCY</span>
            </p>
            <p className="text-lg md:text-xl">
              <span>OPEN TO THE WORLD</span>
            </p>
            <p className="text-7xl text-[#ECC86E] md:text-8xl font-extrabold py-12 md:py-12 lg:py-20">
              ELEVATE
            </p>
            <p className="text-lg md:text-xl">
              <span>YOUR BRAND BEYOND ORDINARY</span>
            </p>
            <p className="text-lg md:text-xl">
              <span>WHERE AESTHETICS & PRACTICALITY </span>
            </p>
            <p className="text-lg md:text-xl">
              <span>WALTZ TOGETHER</span>
            </p>
          </div>
        </div>
      </div>
      <div className="lg:mt-28">
        <HorizontalScrollCarousel />
      </div>

      {/* <Newsletter trigger={timedPopup} setTrigger={setTimePopout}>
      </Newsletter> */}
    </div>
  );
};

export default Home;
