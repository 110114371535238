import React from 'react'
import { Link } from 'react-router-dom'
import { fallDown as Menu } from 'react-burger-menu'

const Nav = (props) => {
    const { isOpen, onClose } = props;
    const handleClick = () => {
        onClose();
    }

    return (
        <Menu id="nav" className='z-50 md:py-24 lg:py-24 bg-slate-50' noOverlay right width={200} customBurgerIcon={false} isOpen={isOpen} {...props}>
            <div className='text-xs md:text-base flex flex-col list-none space-y-12 lg:text-xl px-12 pt-12 md:pt-0 font-semibold'>
                <li className='hover:underline'>
                    <Link to="/about" onClick={handleClick}>About</Link>
                </li>
                <li className='hover:underline'>
                    <Link to="/work" onClick={handleClick}>Work</Link>
                </li>
                <li className='hover:underline'>
                    <Link to="/contact" onClick={handleClick}>Contact</Link>
                </li>
            </div>
        </Menu>
    )
}

export default Nav