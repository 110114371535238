import './App.css';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import About from './pages/About';
import Work from './pages/Work';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Press from './pages/Press';
import Contact from './pages/Contact';

const Layout = () => {
  
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/press",
        element: <Press />,
      },
      {
        path: "/work",
        element: <Work />,
      },
      {
        path: "/contact",
        element: <Contact />
      }
    ],
  },
]);

function App() {
  return (
    <div className="App font-montserrat flex flex-col min-h-screen">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
