import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import mainLogo from '../assets/lustrelaudlogo.png'
import Hamburger from 'hamburger-react'
import Nav from './Nav'

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const closeNav = () => {
    setIsNavOpen(false);
  }

  return (
    <nav id='outer-container' className='top-0 fixed lg:w-full w-screen z-50 bg-slate-50 bg-opacity-50 lg:pb-4 pt-2'>
      <div>
        <ul className="px-4 sm:px-0 flex flex-wrap w-full justify-between sm:gap-5 sm:justify-between sm:items-center sm:ml-6 lg:w-full">
          <li id='Lustre-and-Laud-logo' className="w-24 lg:w-1/12 md:w-2/12 pt-4">
            <Link to="/">
              <img src={mainLogo} alt="main-logo"></img>
            </Link>
          </li>
          <div id="drop-down-menu" className='flex justify-between '>
            <div className="space-y-2 md:pr-12 lg:pr-12 z-50">
              <Hamburger toggled={isNavOpen} toggle={setIsNavOpen} />
              <Nav isOpen={isNavOpen} onClose={closeNav}/>
            </div>
          </div>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar
