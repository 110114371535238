import React from "react";
import { useRef } from "react";
import andrea1 from "../assets/andrea1.png";
import andrea2 from "../assets/andrea2.png";
import andrea3 from "../assets/andrea3.png";
import andrea4 from "../assets/andrea4.png";
import andrea5 from "../assets/andrea5.png";
import andrea6 from "../assets/andrea6.png";
import andrea7 from "../assets/andrea7.png";
import { motion, useTransform, useScroll } from "framer-motion";
import { Link } from "react-router-dom";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const data = [
  {
    title: "The Philosophy",
    text:
      "We're not just a design agency; we're the maestros of aesthetics and practicality, orchestrating a symphony that transforms ideas into visual masterpieces.",
    specialty:
      "Visual Identities | Digital Content | Website Design Packaging | Print Collateral | Creative Advertising",
    original: andrea1,
    contact: "Work with us ↗"
  },
  {
    title: "Harmony Discovery",
    text:
      "We engage in a thorough client consultation to understand the brand's essence, values, and objectives. By conducting brand workshops we uncover the unique voice, identity preferences, and practical requirements.",
    original: andrea2
  },
  {
    title: "Creative Convergence",
    text:
      "We fuse artistic inspiration with pragmatic insights to birth compelling concepts. We have brainstorming sessions to spark creative ideas—collaborating mood board creations to visualize the design direction.",
    original: andrea3
  },
  {
    title: "Sketch Symphony",
    text:
      "We translate concepts into initial sketches, focusing on the interplay of form and function. By executing this step, we capture the visual narrative. Eventually, iterating and refining sketches based on feedback and insights.",
    original: andrea4
  },
  {
    title: "Digital Symphony",
    text:
      "Use industry-leading design software for precision and creativity, we bring designs to life in the digital realm. We focus on maintaining a seamless blend of aesthetics and functionality.",
    original: andrea5
  },
  {
    title: "Final Performance",
    text:
      "We present the polished design masterpiece to the client with a flourish. Showcasing the design in a comprehensive presentation, we address client feedback and make final adjustments.",
    original: andrea6
  },
  {
    title: "Continuous Crescendo",
    text:
      "We support the client post-launch and continuously maintain a strong relationship in order to make other design decisions and enhancements!",
    original: andrea7,
    contact: "Work with us ↗"
  }
];
const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef
  });

  const calculatePercentage = (screenHeight, scaleFactor) => {
    const percentage = ((-scaleFactor * screenHeight) / screenHeight) * 100;
    return percentage;
  };

  const getScaleFactor = () => {
    const largestScreenFactor = 0.80;
    const largeScreenFactor = 0.83;
    const mediumScreenFactor = 0.90;
    const smallScreenFactor = 0.96;

    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      return smallScreenFactor; // Small screens
    } else if (screenWidth < 1024) {
      return mediumScreenFactor; // Medium screens
    } else if (screenWidth < 1536) {
      return largeScreenFactor; // Large screens
    } else {
      return largestScreenFactor;
    }
  };

  const screenHeight = window.innerHeight;
  const scaleFactor = getScaleFactor();
  const adjustPercentage = calculatePercentage(screenHeight, scaleFactor);
  console.log("adjust percentage: ", adjustPercentage);

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    ["1%", `${adjustPercentage}%`]
  );

  return (
    <div>
      <section
        ref={targetRef}
        className="relative h-[300vh] md:h-[300vh] lg:h-[300vh]"
      >
        <div className="sticky top-0 flex h-screen items-center overflow-hidden">
          <motion.div
            style={{ x }}
            className="h-full flex gap-4 pt-16 md:pt-20 lg:pt-16 xl:pt-16"
          >
            {data.map((post, index) => (
              <div
                key={index}
                className="flex w-[755px] md:w-[915px] lg:w-[950px] xl:w-[1010px] px-2 lg:px-0 lg:pl-12"
              >
                <div className="flex flex-col items-start pt-4 md:pt-4 md:ml-6 lg:pt-12 xl:pt-12">
                  <h1 className="font-bold md:text-xl pb-4 md:pb-4 lg:text-xl">
                    {post.title}
                  </h1>
                  <p className="text-xs md:text-base lg:text-base text-left font-medium lg:pt-4">
                    {post.text}
                  </p>
                  <p className="mt-2 text-xs md:text-base lg:text-base text-left font-medium lg:pt-4">
                    {post.specialty}
                  </p>
                  <p id="contact-link" className="mt-4 font-bold md:text-base hover:underline uppercase">
                    <Link to="/contact">{post.contact}</Link>
                  </p>
                </div>
                <img
                  src={post.original}
                  alt={post.title}
                  className="lg:w-[500px] lg:h-screen pl-4 md:pl-16 lg:pl-16 lg:pt-8"
                />
              </div>
            ))}
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default HorizontalScrollCarousel;
