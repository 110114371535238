import React from 'react'
import { Link } from 'react-router-dom';
const About = () => {
  return (
    <div className="mt-28">
      <div className="lg:text-sm 2xl:text-lg text-left px-6 lg:pl-20 md:w-5/6 lg:w-1/2 2xl:w-3/7 2xl:pt-12">
        <div className="mb-8">
          {/* <img src="https://placehold.co/600x400" alt="temporary-pic" className='w-full'></img> */}
          <p className="mb-8 mt-8">
            At Lustre & Laud, we believe in the transformative power of design
            and the impact it can have on the success of a brand. Our founder,
            Naomi, brings a fresh perspective and a keen eye for innovation,
            infusing every project with a touch of her unique flair. With a
            commitment to excellence and a dedication to staying ahead of the
            curve, Lustre & Laud is poised to redefine the way your brand is
            perceived in the market. What sets us apart is our holistic approach
            to branding. We delve deep into understanding your business, your
            values, and your goals to craft a visual identity that not only
            captures the essence of your brand but also resonates with your
            target audience. Our goal is to create a seamless and memorable
            experience for your customers, one that forges lasting connections.
          </p>
          <p>
            Our services encompass a wide spectrum, ranging from brand strategy
            and packaging design to web development and digital marketing.
            Whether you are a startup looking to make a bold entrance or an
            established brand seeking a refresh, Lustre & Laud is here to guide
            you through the creative journey. Collaboration is at the heart of
            what we do. We work closely with our clients, ensuring that their
            vision is not only realized but surpassed. Our process is
            transparent, communicative, and tailored to suit the unique needs of
            each client. We pride ourselves on delivering not just visually
            stunning designs, but solutions that address your business
            challenges and propel you towards success.
          </p>
          <Link to="/contact" >
            <p id="contact-link" className='py-8 hover:underline'>CONTACT US</p>
          </Link>
        </div>
      </div>
    </div>
    
  );
}

export default About
