import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import DOMPurify from 'dompurify';

const Contact = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    number: "",
    message: ""
  });
  const [submitStatus, setSubmitStatus] = useState(0);

  const form = useRef();

  const handleChange = (e) => {
    const {name, value} = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setFormValues({...formValues, [name]: sanitizedValue})
  }

  function handleSubmit(event) {
    event.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_KEY, process.env.REACT_APP_EMAILJS_TEMPLATE_KEY, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
        console.log(result.status);
        setSubmitStatus(result.status);
      }, (error) => {
        console.log("Error: ", error.text);
        setSubmitStatus(error.status);
      });
      
    setFormValues({
      name: "",
      email: "",
      number: "",
      message: ""
    });

    event.target.reset();
  };

  //TODO:
  // Test on PC
  useEffect(() => {
    let timeoutId;
    if(submitStatus === 200 || submitStatus === 500){
      timeoutId = setTimeout(() => {
        setSubmitStatus(0);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, )

  return (
    <div className="pt-28 md:pt-44 md:flex lg:pt-48 2xl:pt-56 lg:mb-48">
      <div className="lg:text-justify px-6 lg:pl-28 md:w-11/12 lg:w-full flex flex-col lg:flex-row lg:pt-16">
        <p className="text-left text-sm 2xl:pl-12 lg:text-2xl 2xl:text-3xl font-extrabold lg:w-full 2xl:w-full mb-12">
          Lustre & Laud is a creative agency that was established to elevate
          brands and businesses through innovative and visually captivating
          solutions, seamlessly blending artistry with strategy to create
          compelling narratives.
        </p>
      </div>
      <div className="lg:w-full px-6 w-full lg:pt-16 ">
        <form ref={form} id="contact-form" className="text-xs md:text-xs lg:text-base flex flex-col lg:pl-20 2xl:pl-12 md:w-4/5 lg:w-2/3 2xl:w-1/2" onSubmit={handleSubmit}>
          <input
            id="full_name"
            type="text"
            name='name'
            required
            placeholder="Name (required)"
            className="bg-gray-300 h-8 lg:h-12 mb-4 placeholder-black pl-2 rounded"
            onChange={handleChange}
            value={formValues.name}
          ></input>
          <input
            id="user_email"
            type="email"
            name='email'
            required
            placeholder="Email (required)"
            className="bg-gray-300 h-8 lg:h-12 mb-4 placeholder-black pl-2 rounded"
            onChange={handleChange}
            value={formValues.email}
          ></input>
          <input
            id="phone_number"
            type="text"
            name='number'
            placeholder="Phone Number (123)-123-1234"
            className="bg-gray-300 h-8 lg:h-12 mb-4 placeholder-black pl-2 rounded"
            onChange={handleChange}
            value={formValues.number}
          ></input>
          <input
            id="message_question"
            type="text"
            required
            name='message'
            placeholder="How can we help? (required)"
            className="bg-gray-300 h-8 lg:h-12 mb-4 placeholder-black pl-2 rounded"
            onChange={handleChange}
            value={formValues.message}
          ></input>
          <div className='flex items-center pt-2 pb-2'>
            <input id='contact-checkbox' type="checkbox" className='bg-gray-300 mr-3 md:w-12 md:h-12'></input>
            <label className='text-xs md:text-base sm:pt-8 lg:pt-0 text-xs lg:text-xs text-left'>
              Subscribe to our newsletter for exclusive insights, insider
              updates, and curated content that unveils the latest trends in
              design, marketing, and creativity.
            </label>
          </div>
          <div className='mt-4 md:mt-4'>
            <button id='contact-submit-btn' className='bg-black text-white rounded h-16 w-32 text-bold'>Submit</button>
            {submitStatus === 200 && (
                <p className='text-green-500 pt-4' dangerouslySetInnerHTML={{ __html: "Your message has been sent successfully." }} />
            )}
            {submitStatus === 500 && (
                <p className='text-red-600 pt-4' dangerouslySetInnerHTML={{ __html: "Error submitting form. Please try again." }} />
            )}
          </div>
        </form>
      </div>
    </div>
   
  );
}

export default Contact