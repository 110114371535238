import React from 'react'

const Work = () => {
  return (
    // <div>
    //   <WorkGallery images={posts}/>
    // </div>
    <div className="flex items-center justify-center min-h-screen">
      <p className="text-4xl sm:text-7xl italic pb-32 font-extrabold">
        Coming Soon...
      </p>
    </div>
  );
}

export default Work
